<!--

Пропы:

minRequiredLength: Минимальная длина значения инпута, при которой может вызваться функция
prop: Имя свойства передаваемых объектов, которое должно передаваться с эмитом в родителя
field: Имя свойства передаваемого объекта, значение которого будет показано в дропдауне и инпуте
getData: Функция, которая должна быть вызвана в update. (Передавать нужно саму функцию, а не вызывать её)
loader: Переключатель показа лоадера
seed: Уникальный идентификатор, при изменении которого вызывается getData. (Также можно вызывать update через ref из родителя)

Слот #empty используется для передачи заглушки и показывается когда в data нет итемов.

-->

<template>
    <b-autocomplete
        :key="locale"
        ref="autocomplete"
        v-model="label"
        :data="filteredData"
        :field="field"
        :loading="isLoadingInput"
        append-to-body
        v-bind="attrs"
        @infinite-scroll="debouncedUpdate"
        @select="select($event)"
        @input="updateData($event)">
        <template slot-scope="{ option, index }">
            <slot
                :index="index"
                :option="option">
                <div class="media">
                    <div class="media-content">
                        <slot
                            :field="field"
                            :option="option"
                            name="text">
                            {{ (field ? option[field] : option) || option.id }}
                        </slot>
                    </div>
                </div>
            </slot>
        </template>
        <template #empty>
            <h6 class="title is-6">
                <slot name="empty">
                    {{ $t(`common.components.autocomplete.values.empty`) }}
                </slot>
            </h6>
        </template>
    </b-autocomplete>
</template>

<script>
  import _debounce from "lodash/debounce";
  
  export default {
    name: "Autocomplete",
    inheritAttrs: true,
    components: {},
    props: {
      minRequiredLength: {
        type: Number,
        default: 2
      },
      prop: {
        type: String,
        default: "id"
      },
      value: {
        type: null,
        default: null
      },
      field: {
        type: String,
        default: null
      },
      getData: {
        type: Function,
        required: true
      },
      loader: {
        type: Boolean,
        default: true
      },
      seed: {
        type: null,
        default: null
      },
      forceLoading: {
        type: Boolean,
        default: false
      }
    },
    
    created () {
      this.debouncedUpdate();
    },
    
    mounted () {
      //FIXME: Пока буефай не научится в minLength https://github.com/buefy/buefy/issues/2573
      this.$refs.autocomplete.$refs.input.$refs.input.minLength = this.minRequiredLength;
    },
    
    data () {
      return {
        label: "",
        rawData: null,
        isLoading: false,
        offset: 0,
        items: []
      };
    },
    
    computed: {
      locale () {
        return this.$root.$i18n.locale;
      },
      
      filteredData () {
        return this.items.filter(option => {
          return ((this.field ? option[this.field] : option) || "")
            .toString()
            .toLowerCase()
            .indexOf(this.label.toLowerCase()) >= 0;
        });
      },
      
      isLoadingInput () {
        if (this.forceLoading) {
          return this.forceLoading || this.isLoading;
        }
        
        return this.loader && this.isLoading;
      },
      
      debouncedUpdate () {
        return _debounce(this.update, 400, { leading: true, trailing: true });
      },
      
      attrs () {
        return {
          clearable: true,
          expanded: true,
          checkInfiniteScroll: true,
          openOnFocus: true,
          iconPack: "far",
          ...this.$attrs
        };
      }
    },
    
    methods: {
      updateData () {
        this.debouncedUpdate();
      },
      
      forceUpdateData () {
        this.rawData = null;
        this.items = [];
        this.offset = 0;
        this.updateData();
      },
      
      select (event) {
        if (event) {
          this.$emit("select", event);
          this.$emit("input", event[this.prop]);
        } else {
          this.$emit("select", null);
          this.$emit("input", null);
        }
      },
      
      async update () {
        if (this.getData) {
          const count = (this.rawData || { count: Infinity }).count || Infinity;
          if (count > this.offset && (this.label.length === 0 || this.label.length >= this.minRequiredLength)) {
            this.isLoading = true;
            this.rawData = await this.getData(this.offset, this.label);
            this.isLoading = false;
            
            if (this.rawData && this.rawData.items.length > 0) {
              this.offset += this.rawData.items.length;
              this.items = [...this.items, ...this.rawData.items];
            }
          }
        }
      }
    },
    
    watch: {
      locale () {
        this.forceUpdateData();
      },
      
      async value (value) {
        await this.updateData();
        if (value === "" || value === null) {
          this.label = "";
        } else {
          const item = this.items.find(el => el[this.prop] === value);
          this.label = item[this.field];
        }
      },
      
      items (value, oldVal) {
        if (this.value !== null && oldVal.length === 0) {
          const item = value.find(el => el[this.prop] === this.value);
          if (item) this.label = item[this.field];
        }
      },
      
      seed: {
        deep: true,
        handler: function () {
          this.items = [];
          this.offset = 0;
          
          this.update();
        }
      }
    }
  };
</script>

<style scoped lang="scss"></style>
